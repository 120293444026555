import React from "react";
import Cell from "./Cell";

function Yantra(props) {
    const {numbers, dob} = props;

    let cellNumbers = {};

    // Init cells.
    for (let index = 1; index <= 9; index++) {
        cellNumbers[index] = [];
    }

    // Add numbers to cells.
    for (let key in numbers) {
        cellNumbers[numbers[key].value].push(numbers[key])
    }

    let cells = [];
    for (let index = 1; index <= 9; index++) {
        cells.push(<Cell number={index} numbers={cellNumbers[index]} key={dob +':' + index}/>)
    }

    return (
        <div className='yantra'>
            {cells}
        </div>
    )
}

export default Yantra