import React from "react";
import Number from "./Number"

export default function Cell(props) {
    const number = props.number;
    const numbers = props.numbers;

    const numberElements = numbers.map(number => <Number number={number} key={number.type + '-' + number.value}/>)
    return <div className={'yantra__cell yantra__cell__' + number}>
        <div className='yantra__cell_inner'>
            {numberElements}
        </div>
    </div>
}
