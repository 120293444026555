import React from "react";
import {planet} from "../utils";

function Number(props) {
    const {number} = props;

    let classes = ['yantra__number'];
    if (number.type !== undefined) {
        classes.push('type-' + number.type)
    }
    return (
        <span className={classes.join(' ')} title={planet(number.value)}>{number.value}</span>
    )
}

export default Number