import React, { Suspense } from "react";
import {sum} from "../utils";
import Period from "./Period";

export default function PeriodsList(props) {
    console.log(props)

    const year = props.year;
    const month = props.month;
    const day = props.day;
    const jiva = props.jiva;

    let currentYear = year;
    let period = jiva;

    let periodElements = [];

    for (let age = 0; age < 15; age++) {
        for (let yearInPeriod = 1; yearInPeriod <= period; yearInPeriod++) {
            periodElements.push(<Suspense fallback={<p>loading...</p>}><Period period={period} yearInPeriod={yearInPeriod} numbers={props.numbers}
                                        currentYear={currentYear} year={year} month={month} day={day} key={currentYear}/></Suspense>)
            currentYear++;
        }
        period = sum(period + 1);
    }

    return (
        <div className='periods-wrapper'>
            <h2 key={'heading'}>Periods</h2>
            <div className='periods-list' key={'list'}>
                {periodElements}
            </div>
        </div>
    )
}
