import React from "react";

export default function Datepicker(props) {
    const {date, onDateChange} = props;
    console.log(onDateChange);
    return (
        <div id="datepicker">
            <input type="date" id="date" defaultValue={date} />
            <span id="calculate" onClick={onDateChange}>Calculate</span>
        </div>
    )
}