import React from "react";
import Yantra from "./Yantra";
import {planet, yearNumber} from "../utils";

export default function Period(props) {
    const {year, month, day, currentYear, period, yearInPeriod} = props;
    let periodNumbers = [...props.numbers];

    periodNumbers.push({value: period, type: 'period'})
    periodNumbers.push({value: yearNumber(year, month, day, currentYear), type: 'yearnum'})

    return (
        <div className='period'>
            <h3 key='head'>Period <span
                className="yantra__number type-period">{period}</span> ({planet(period)})</h3>
            <h4 key='year'>Year {yearInPeriod}</h4>
            <h4 key='date'>{currentYear}-{month}-{day} &mdash; {currentYear + 1}-{month}-{day}</h4>
            <Yantra key='yantra' dob={`${year}-${month}-${day}`} numbers={periodNumbers}/>
        </div>
    )
}
