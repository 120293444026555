import React, {Component} from "react";
import Yantra from "./Yantra";
import PeriodsList from "./PeriodsList";
import {dateToYantra} from "../utils";
import Datepicker from "./Datepicker";

class App extends Component {

    state = {
        date: new Date('1984-10-07')
    }

    componentDidMount() {
        document.getElementById('root').classList.remove('progress');
    }

    componentDidUpdate() {
        document.getElementById('root').classList.remove('progress');
    }

    render() {

        if (this.state.date) {
            const year = this.state.date.getFullYear();
            const month = this.state.date.getMonth() + 1;
            const day = this.state.date.getDate();

            const data = dateToYantra(year, month, day);

            return (
                <div>
                    <Datepicker date={this.state.date.toLocaleDateString('fr-CA')}
                                onDateChange={this.handleDateChange}/>
                    <ul id={'main-numbers'}>
                        <li>Jiva: <span className="yantra__number type-jiva">{data.jiva}</span></li>
                        <li>Karma: <span className="yantra__number type-karma">{data.karma}</span></li>
                        <li>Dharma: <span className="yantra__number type-dharma">{data.dharma}</span></li>
                    </ul>

                    <Yantra dob={`${year}-${month}-${day}`} numbers={data.numbers}/>

                    <PeriodsList numbers={data.numbers} jiva={data.jiva} year={year} day={day} month={month}/>
                </div>
            )
        } else {
            return (
                <Datepicker onDateChange={this.handleDateChange}/>
            )
        }
    }

    handleDateChange = (event) => {
        this.setState({
            date: new Date(document.getElementById('date').value)
        })
        document.getElementById('root').classList.add('progress');
    }
}


export default App