export function dateToYantra(year, month, day) {
    let numbers = [];

    const jiva = sum(day);
    numbers.push({value: jiva, type: 'jiva'});

    if (day !== jiva) {
        numbers = numbers.concat(toDigits(day).filter(number => {
            // TODO Check.
            return number !== jiva;
        }).map(number => {
            return {'value': number, 'type': 'day'}
        }))
    }

    const dharma = sum(jiva + sum(month));

    numbers = numbers.concat(toDigits(month).map(number => {
        return {'value': number, 'type': 'month'}
    }))

    const karma = sum(dharma + sum(year));
    numbers.push({value: karma, type: 'karma'});

    // Remove first two digits.
    const shortYear = year - 100 * Math.floor(year / 100);

    numbers = numbers.concat(toDigits(shortYear).map(number => {
        return {'value': number, 'type': 'year'}
    }))

    return {jiva, dharma, karma, numbers}
}

export function sum(number) {

    if (number < 10) {
        return number;
    }

    const otherDigits = Math.floor(number / 10);

    const lastDigit = number - 10 * otherDigits;

    const sumOtherDigits = sum(otherDigits);

    return sum(lastDigit + sumOtherDigits);
}

export function toDigits(number) {

    if (number < 10) {
        return [number];
    }

    let digits = [];
    const otherDigits = Math.floor(number / 10);

    const digit = number - 10 * otherDigits;

    if (digit > 0) {
        digits.push(digit);
    }
    digits = digits.concat(toDigits(otherDigits));

    return digits;
}

export function planet(key) {
    const planets = {
        1: 'Surya',
        2: 'Chandra',
        3: 'Guru',
        4: 'Rahu',
        5: 'Buddha',
        6: 'Shukra',
        7: 'Ketu',
        8: 'Shani',
        9: 'Mangala',
    }

    return planets[key];
}

function dayToPlanet(key) {
    const planets = {
        0: 1,
        1: 2,
        2: 9,
        3: 5,
        4: 3,
        5: 6,
        6: 8,
    }

    return planets[key];
}

function addLeadingZero(number) {
    return number < 10 ? ('0' + number) : number;
}

export function yearNumber(year, month, day, currentYear) {
    // Remove first two digits.
    // TODO Check
    // const shortYear = year - 100 * Math.floor(year / 100);
    const shortYear = currentYear - 100 * Math.floor(currentYear / 100);

    const thisYearDate = new Date(currentYear + '-' + addLeadingZero(month) + '-' + addLeadingZero(day));

    return sum(day + month + shortYear + dayToPlanet(thisYearDate.getDay()));
}